<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="briefcase-outline"></b-icon>
          <span>{{ $ml.get('titles_strategies_public') }}</span>
        </h4>
        <b-button tag="router-link" type="is-primary" outlined :to="{ path: `/strategies` }">
          <span>{{ $ml.get('strategies_my_strategies') }}</span>
        </b-button>
      </div>
    </div>
<!--    <div class="columns">-->
<!--      <div class="column is-12 has-text-right">-->
<!--        * {{ $ml.get('strategies_public_notice') }}-->
<!--      </div>-->
<!--    </div>-->
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="name" :label="$ml.get('strategies_strategy')" sortable cell-class="leftal" v-slot="props">
            <h4 style="font-weight: 700;font-size: 18px;text-decoration: underline;">{{ props.row.name }}</h4>
            <b-tag type="is-danger" v-if="props.row.mode === 'LIMITED'">{{ $ml.get('strategies_mode_limited') }}</b-tag>
            <b-tag type="is-success" v-if="props.row.mode === 'PUBLIC'">{{ $ml.get('strategies_mode_public') }}</b-tag>
            <span>{{ props.row.info }}</span>
          </b-table-column>
          <b-table-column field="risk_level" width="130" :label="$ml.get('strategies_risk')" sortable cell-class="leftal" v-slot="props">
            <div class="strategies__risk">
              <StrategyRiskLevel :lvl="props.row.risk_level"/>
            </div>
          </b-table-column>
          <b-table-column field="tax_percent" width="50" :label="$ml.get('strategies_fee')" sortable v-slot="props">
            {{ props.row.tax_percent }}%
          </b-table-column>
          <b-table-column field="rooms" :label="$ml.get('strategies_users')" width="100" sortable v-slot="props">
            {{ new Intl.NumberFormat().format(props.row.rooms) }}
          </b-table-column>
          <b-table-column field="bets_count" :label="$ml.get('strategies_bets_count')" width="100" sortable v-slot="props">
            {{ props.row.bets_count_str }}
          </b-table-column>
          <b-table-column field="roi" label="ROI" width="90" sortable v-slot="props">
            {{ props.row.roi }} %
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Money from "@/components/Money";
import StrategyRiskLevel from "@/components/StrategyRiskLevel";

export default {
  name: "Index",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
    }
  },
  components: {
    Money, StrategyRiskLevel
  },
  methods: {
    retrieve: function () {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `strategies/public_list`).then(resp => {
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_strategies_public')
    }
  }
}
</script>

<style scoped>
</style>