<template>
  <div
      :class="`risk-lvl--${lvl}`"
      class="risk-lvl"
  >
    <img src="../assets/risk.svg">

    <div class="risk-lvl__arrow">
      <img src="../assets/risk-arrow.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'StrategyRiskLevel',
  props: {
    lvl: {
      type: Number,
      default: 1
    }
  }
}
</script>